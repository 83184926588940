.table-container {
  overflow-x: auto;
  max-width: 100%; /* Adjust as needed */
}

.custom-badge {
  font-size: 8px;
  padding-top: 6px;
  border-radius: 5px;
  background-color: #ffe700;
  color: #000;
}

.table-container {
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 60vh; /* Set a maximum height for the table */
  background: white;
}

.table-container thead tr th {
  position: sticky !important;
  padding: 10px;
  top: 0 !important;
  z-index: 999 !important;
  background: white;
}
