/* .main {
  width: 100%;
  height: 100vh;
  margin: 20px auto;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  overflow-y: auto;
} */

.main::-webkit-scrollbar {
  display: none;
}

/* .table-container-dashboard {
  max-width: max-content;
  overflow-x: auto;
  min-height: 100%;
  position: relative;
  z-index: 1;
  padding-bottom: 100px;
} */


.table-container-dashboard::-webkit-scrollbar {
  width: 6px; /* Slim scrollbar width */
  height: 0;
}

.table-container-dashboard::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
}

.table-container-dashboard::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Background color of the scrollbar track */
}

.table {
  width: 100%;
  min-height: max-content;
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

.table::-webkit-scrollbar {
  display: none;
}

.th,
.td {
  padding: 0;
  overflow-x: visible;
  height: 50px;
}

.tbody {
  position: relative;
}

.th {
  min-width: max-content;
}

.reservation-cell {
  width: 150px;
  border: 1px solid #ddd;
  position: relative;
}

.reservation-band {
  height: 37px;
  color: white;
  padding: 2px;
  padding-top: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.table, .th, .td {
  border-collapse: collapse; 
}
