.horizontal-slider {
  width: 100%;
  height: 20px;
}

.slider-thumb {
  background-color: #007bff; 
  width: 20px;
  height: 20px;
  border-radius: 20px;
}

.slider-track {
  background-color: #ccc;
  height: 6px;
  margin-top: 7px;
  border-radius: 20px;
}