.container-fluid {
  background-color: #f8f9fa;
  min-height: 100vh;
  padding: 20px;
}

.card {
  border: none;
  border-radius: 10px;
  overflow: hidden;
}

.card-header {
  font-size: 1.25rem;
  font-weight: 600;
}

.shadow-sm {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.bg-primary {
  background-color: #007bff !important;
}

.bg-success {
  background-color: #28a745 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.text-white {
  color: #fff !important;
}

.table {
  margin-bottom: 0;
}

.table thead th {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
}

.table tbody tr:hover {
  background-color: rgba(0, 123, 255, 0.1);
}

.dark-theme {
  background-color: #121212;
  color: #e0e0e0;
}

.dark-theme .container-fluid {
  background-color: #121212;
  color: #e0e0e0;
}

.dark-theme .card {
  background-color: #1e1e1e;
  color: #e0e0e0;
  border: 1px solid #333;
}

.dark-theme .card-header {
  background-color: #2c2c2c !important;
  color: #ffffff !important;
  border-bottom: 1px solid #444;
}

.dark-theme .table {
  color: #e0e0e0;
}

.dark-theme .table thead {
  background-color: #2c2c2c;
  color: #ffffff;
}

.dark-theme .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.dark-theme .table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.1);
}

.dark-theme .form-control {
  background-color: #2c2c2c;
  color: #e0e0e0;
  border: 1px solid #444;
}

.dark-theme .form-control:focus {
  background-color: #3c3c3c;
  color: #ffffff;
  border-color: #007bff;
}

.dark-theme .progress-wrapper {
  background-color: #333 !important;
}
