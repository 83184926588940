.main-cr {
    width: 100%;
    margin: 20px auto;
    /* background: white; */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .filters-cr {
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    /* background-color: #fafafa; */
    display: flex;
    gap: 20px;
  }
  
  .h3-cr {
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .filter-group-cr {
    margin-bottom: 10px;
  }
  
  .filter-label-cr {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .filter-options-cr .label-cr {
    margin-right: 15px;
  }
  
  .filter-select-cr {
    padding: 5px;
    border-radius: 4px;
    /* border: 1px solid #ccc; */
  }
  

  
  