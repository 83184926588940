.feedImage {
  width: 4rem;
  height: auto;
  grid: flex;
}

.imageDiv {
  display: grid;
  grid-template-columns: 1;
}

.feedBackCard {
  margin-top: 100px;
  margin-bottom: 100px;
  width: 30rem;
  height: auto;
  margin-left: 400px;
}
.messageInbox {
  width: 20rem;
  height: auto;
}

.messageDiv {
  margin-left: 25px !important;
}

.feedbackInput {
  width: 25rem;
  outline-color: blue;
}

.feedbackButton {
  width: 100px;
  margin-top: 20px;
  margin-left: 200px;
  border-color: #ffe500;
  background-color: #ffe500;
  font-weight: bolder;
  /* margin-left: 20rem; */
  cursor: pointer;
}

.feedbackButton:hover {
  scale: 1;
}

.radio {
  display: none;
}
.radio ~ span {
  font-size: 3rem;
  cursor: pointer;
  transition: 0.3s;
}

.emojiDiv {
  position: relative;
  right: 30px;
}
